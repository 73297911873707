import React, { useEffect } from 'react';

const DownloadSL = () => {
  useEffect(() => {
    const link = document.createElement('a');
    link.href = '/static/Readin_Brochure2023_Preview.pdf';
    link.download = 'Shinglee Education Brochure.pdf';
    link.click();
  }, []);

  return (
    <div className="text-center margin-auto">
      <p><img src={require("~images/bett2023/shing_lee_logo_50.png").default} alt="Shinglee Education"/></p>
      <h4>You are downloading Shinglee Education Brochure ...</h4>
    </div>
  );
};

export default DownloadSL;